<template>
  <v-card>
    <v-card-title>
      <span class="headline" v-if="inputModel.createNew"
        ><v-icon>mdi-pencil-plus-outline</v-icon>ロフトカートン予測登録</span
      >
      <span class="headline" v-if="!inputModel.createNew"
        ><v-icon>mdi-pencil-outline</v-icon>ロフトカートン予測編集</span
      >
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="loftCartonForm" v-model="validEntryForm" lazy-validation>
        <v-card-subtitle v-if="!inputModel.createNew">ロフトカートン予測情報</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense v-if="!inputModel.createNew">
            <v-col cols="12" sm="4">
              <v-text-field label="カートン予測番号" v-model="updateModel.cartonNumber" filled dense disabled>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="3">
              <dp-date-picker
                type="date"
                label="出荷予定日"
                v-model="updateModel.shipmentPlanDate"
                :rules="[rules.required]"
              ></dp-date-picker>
            </v-col>
            <v-col cols="12" sm="6">
              <customer-field
                :corporate="corporateCode"
                :code="updateModel.companyStoreCode"
                label="店舗"
                v-model="updateModel.companyStoreCode"
                :rules="[rules.required]"
              ></customer-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="カートン数"
                v-model.number="updateModel.cartonQuantity"
                :rules="[rules.required, rules.maxLength(8), rules.decimal]"
                @change="updateModel.cartonQuantity = stringToNumber($event)"
                filled
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field label="備考" v-model="updateModel.remarks" :rules="[rules.maxLength(100)]" filled dense>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider v-if="!inputModel.createNew"></v-divider>
        <v-card-subtitle v-if="!inputModel.createNew">履歴</v-card-subtitle>
        <v-container style="max-width: inherit" v-if="!inputModel.createNew">
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field label="更新日時" v-model="updateModel.updateDatetime" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="更新者" v-model="updateModel.updateUser" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="登録日時" v-model="updateModel.createDatetime" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="登録者" v-model="updateModel.createUser" filled dense disabled></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="$emit('onDialogClose')">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onCreateClick" v-if="inputModel.createNew">登録</v-btn>
      <v-btn color="primary" @click="onUpdateClick" v-if="!inputModel.createNew">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";
import CustomerField from "../../components/common/fields/CustomerField.vue";
import { corpList as CorpList } from "../../consts/CorpList";
import ConvertUtils from "../../utils/ConvertUtils";

export default {
  name: "LoftCartonEntry",
  props: ["inputModel"],
  data() {
    return {
      stringToNumber: ConvertUtils.stringToNumber,
      validEntryForm: null,
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        phoneNo: Validation.phoneNo,
        mailAddress: Validation.mailAddress,
        zipCode: Validation.isZipcode,
        decimal: (value) => value?.toString().match(/^(\d{0,8})?$/) != null || "不正な形式です",
      },
      updateModel: {},
      defaultModel: {
        cartonNumber: null,
        shipmentPlanDate: null,
        companyStoreCode: "",
        storeName: null,
        cartonQuantity: null,
        remarks: null,
        updateDatetime: null,
        updateUser: null,
        createDatetime: null,
        createUser: null,
      },
      corporateCode: CorpList.LOFT,
    };
  },
  components: { CustomerField },
  watch: {
    inputModel(inputModel) {
      this.initUpdateModel(inputModel);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    initUpdateModel(inputModel) {
      if (inputModel.createNew) {
        // 新規登録の場合、デフォルト値を設定
        this.updateModel = JSON.parse(JSON.stringify(this.defaultModel));
      } else {
        // 編集の場合、引数を設定
        this.updateModel = JSON.parse(JSON.stringify(inputModel));
      }
      if (this.$refs.loftCartonForm) {
        this.$refs.loftCartonForm.resetValidation();
      }
    },
    reset() {
      this.initUpdateModel(this.inputModel);
    },
    async onCreateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const result = await this.$store.dispatch("loftCarton/create", { ...this.updateModel });
          if (ApiStatus.isSystemError(result.data?.header)) {
            return this.redirectError();
          }
          this.loadingOff();
          this.$emit("onEntrySubmit");
        } catch (error) {
          console.error("LoftCartonUpdate::onCreateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    async onUpdateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const result = await this.$store.dispatch("loftCarton/update", { ...this.updateModel });
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$emit("onEditSubmit", result.data.contents.loftCarton);
              this.reset();
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
              this.$dialog.warning({
                title: `ロフトカートン予測編集`,
                text: result.data.header.messages[this.updateModel.cartonNumber].join(),
                actions: ["OK"],
              });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("LoftCartonUpdate::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    validate() {
      const isValid = this.$refs.loftCartonForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
  },
  beforeMount() {
    this.initUpdateModel(this.inputModel);
  },
};
</script>
