<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title><v-icon>mdi-database-search</v-icon>ロフトカートン予測一覧</v-app-bar-title>
        <v-spacer></v-spacer>
        <error-grid-dialog ref="errorGrid" width="1100px" height="350px"></error-grid-dialog>
        <v-form ref="searchForm" v-model="validSearchForm" lazy-validation>
          <search-conditions @search="onBtnSearch" max-height="100%">
            <customer-field
              ref="customer"
              :corporate="corporateCode"
              label="取引先名"
              v-model="searchModel.storeName"
              :attach="false"
            ></customer-field>
            <dp-date-picker
              v-model="searchModel.createDateFrom"
              type="date"
              label="出荷予定日From"
              :rules="[rules.createDateFrom]"
              dense
            ></dp-date-picker>
            <dp-date-picker
              v-model="searchModel.createDateTo"
              type="date"
              label="出荷予定日To"
              :rules="[rules.createDateTo]"
              dense
            ></dp-date-picker>
          </search-conditions>
        </v-form>
        <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-button icon="mdi-pencil-plus-outline" @click="onBtnInsert" v-if="allowedAction('C090202')"
          >新規登録</tooltip-icon-button
        >
        <v-divider vertical></v-divider>
        <tooltip-icon-button
          :disabled="!selectedRow"
          icon="mdi-pencil-outline"
          @click="onBtnUpdate"
          v-if="allowedAction('C090203')"
          >編集</tooltip-icon-button
        >
        <v-dialog v-model="editDialog.show" :max-width="editDialog.width" persistent scrollable>
          <loft-carton-entry
            :inputModel="editModel"
            @onDialogClose="onDialogClose"
            @onEntrySubmit="onEntrySubmit"
            @onEditSubmit="onEditSubmit"
            v-if="editDialog.show"
          ></loft-carton-entry>
        </v-dialog>
        <tooltip-icon-button
          :disabled="selectionRows.length === 0"
          icon="mdi-trash-can-outline"
          @click="onBtnDelete"
          v-if="allowedAction('C090204')"
          >削除</tooltip-icon-button
        >
        <tooltip-icon-button icon="mdi-download" @click="onBtnExport" v-if="allowedAction('C090205')"
          >CSVダウンロード</tooltip-icon-button
        >
        <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="detailSelected"
          >詳細表示</tooltip-icon-toggle-button
        >
      </v-app-bar>
    </v-row>

    <v-row style="height: 100%">
      <v-col
        :style="gridStyle"
        :cols="detailSelected && !infoMaximum ? 8 : 12"
        v-show="!detailSelected || !infoMaximum"
      >
        <v-row>
          <v-col cols="3">
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                カートン数合計：{{ this.cartonQuantityTotal | comma }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <ag-grid-vue
          id="LoftCartonLinst"
          class="ag-theme-alpine"
          style="height: 90%"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :columnTypes="columnTypes"
          :rowData="rowData"
          :alwaysShowHorizontalScroll="true"
          :pagination="true"
          :paginationPageSize="selectedPageSize"
          :suppressCellSelection="true"
          :enableCellTextSelection="true"
          :suppressCsvExport="false"
          :suppressExcelExport="true"
          :defaultCsvExportParams="defaultCsvExportParams"
          :localeText="localeText"
          rowSelection="multiple"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
          @filter-changed="calcTotalItem"
          :getRowNodeId="
            (data) => {
              return data.cartonNumber;
            }
          "
        >
        </ag-grid-vue>
      </v-col>
      <v-col
        v-if="detailSelected"
        id="SuppliersConversionInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 4"
      >
        <v-divider vertical></v-divider>
        <loft-carton-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :cartonNumber="selectedRow != null ? selectedRow.cartonNumber : null"
        ></loft-carton-infos>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import moment from "moment";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { statuses as ApiStatus } from "../../libs/api-client";
import LoftCartonInfos from "../../components/loftCarton/LoftCartonInfos.vue";
import LoftCartonEntry from "../../components/loftCarton/LoftCartonEntry.vue";
import SearchConditions from "../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import { NumberValueFormatter } from "../../models/ag-grid/valueFormatters";
import { getDisplayDetails, typeFormat } from "../../models/ag-grid/helpers";
import CustomerField from "../../components/common/fields/CustomerField.vue";
import { corpList as CorpList } from "../../consts/CorpList";
import { NumericColumn, FullDateColumn, DateColumn } from "../../models/ag-grid/columnTypes";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import { comma } from "../../filter/NumberFilter";

export default {
  name: "LoftCartonSearch",
  data() {
    return {
      gridStyle: { height: "95%" },
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: null,
      defaultCsvExportParams: null,
      detailSelected: false,
      rowsSelected: null,
      infoMaximum: false,
      selectedPageSize: null,
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      corporateCode: CorpList.LOFT,
      validSearchForm: null,
      cartonQuantityTotal: null,
      rules: {
        createDateFrom: (value) => this.createDateFromRules(value),
        createDateTo: (value) => this.createDateToRules(value),
      },
      searchModel: {
        storeName: null,
        createDateFrom: null,
        createDateTo: null,
      },
      editDialog: { show: false, width: "800px" },
      editModel: {
        createNew: false,
      },
      selectionRows: [],
      cafereoColumnDefs: [
        { headerName: "カートン予測番号", field: "cartonNumber", pinned: "left" },
        { headerName: "出荷予定日", field: "shipmentPlanDate", filter: "agDateColumnFilter" },
        { headerName: "社店CD", field: "companyStoreCode" },
        { headerName: "取引先名", field: "storeName" },
        {
          headerName: "カートン数",
          field: "cartonQuantity",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        { headerName: "備考", field: "remarks" },
        { headerName: "更新日時", field: "updateDatetime", type: "dpDateColumn" },
        { headerName: "更新者", field: "updateUser" },
        { headerName: "登録日時", field: "createDatetime", type: "dpDateColumn" },
        { headerName: "登録者", field: "createUser" },
      ],
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpFullDateColumn: FullDateColumn,
        dpDateColumn: DateColumn,
      },
    };
  },
  components: {
    AgGridVue,
    LoftCartonInfos,
    LoftCartonEntry,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    CustomerField,
    ErrorGridDialog,
  },
  mounted() {
    this.$store.commit("ui/loading", true);
    this.onSearchClick();
    this.gridStyle.height = this.gridHeightSize + "px";
    window.addEventListener("resize", this.handleResize);
  },
  filters: { comma },
  computed: {
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      return getDisplayDetails(this.selectedRow.cartonNumber, this.gridApi, this.columnApi);
    },
  },
  methods: {
    clearFilters() {
      this.gridApi.setFilterModel(null);
      this.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onBtnExport() {
      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.gridApi.exportDataAsCsv({
        allColumns: false,
        onlySelected: this.selectionRows.length > 0,
        fileName: `ロフトカートン予測一覧`,
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      this.selectionRows = this.gridApi.getSelectedRows();
    },
    onBtnInsert() {
      this.editModel = {
        createNew: true,
      };
      this.editDialog.show = true;
    },
    onBtnUpdate() {
      this.editModel = {
        createNew: false,
        ...this.selectedRow,
        shipmentPlanDate: moment(this.selectedRow.shipmentPlanDate).format("YYYY-MM-DD"),
      };
      this.editDialog.show = true;
    },
    async onBtnDelete() {
      try {
        this.loadingOn();
        const messageText = `選択されたロフトカートン予測を削除します <small>(${this.selectionRows.length}件)</small>`;
        const ok = await this.$dialog.confirm({ title: "ロフトカートン予測一覧", text: messageText });
        if (ok) {
          const cancelConditions = {
            cancelConditions: this.selectionRows.map((r) => ({
              cartonNumber: r.cartonNumber,
              lastUpdateDatetime: r.updateDatetime,
            })),
          };

          const result = await this.$store.dispatch("loftCarton/remove", cancelConditions);
          let errorMsg = {};
          let tmpErrorMsg = {};
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.gridApi.applyTransaction({ remove: this.selectionRows });
              this.$dialog.notify.info(`ロフトカートン予測が削除されました (${this.selectionRows.length}件)`, {
                timeout: 2300,
              });
              this.onSearchClick();
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
            case ApiStatus.consts.BUSINESS_ERROR:
              tmpErrorMsg = this.selectionRows.map(function (r) {
                let tmp = null;
                if (result.data.header.messages[r.cartonNumber]) {
                  tmp = {
                    cartonNumber: r.cartonNumber,
                    errorMsg: result.data.header.messages[r.cartonNumber]?.join(),
                  };
                }
                return tmp;
              });
              errorMsg = tmpErrorMsg.filter((r) => r != null);

              this.$refs.errorGrid.open({
                title: "エラーメッセージ",
                columns: [
                  {
                    headerName: "カートン予測番号",
                    field: "cartonNumber",
                    pinned: "left",
                  },
                  { headerName: "エラーメッセージ", field: "errorMsg" },
                ],
                records: errorMsg,
                onClose: async () => {
                  this.selectionRows = [];
                  this.onSearchClick();
                },
              });
              break;
            default:
              this.redirectError();
              break;
          }
        }
      } catch (error) {
        console.error("loftCartonSearch::onBtnDelete", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    calcTotalItem() {
      this.cartonQuantityTotal = 0;
      let rowData = [];
      this.gridApi.forEachNodeAfterFilter((node) => rowData.push(node.data));
      for (let row of rowData) {
        this.cartonQuantityTotal += row.cartonQuantity ? row.cartonQuantity : 0;
      }
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        this.selectionRows = [];
        const response = await this.$store.dispatch("loftCarton/search", this.searchModel);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }

        const result = response.data.contents;
        if (result.over) {
          this.$dialog.warning({
            title: "ロフトカートン予測一覧",
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.loftCartons).length === 0) {
          this.$dialog.warning({
            title: "ロフトカートン予測一覧",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridApi.setRowData(response.data.contents.loftCartons);
        const allColumnIds = this.columnApi.getAllColumns().map((column) => column.colId);
        this.columnApi.autoSizeColumns(allColumnIds);
        this.calcTotalItem();
      } catch (error) {
        console.error("CorporationSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onPageSizeChanged() {
      this.gridApi.paginationSetPageSize(this.selectedPageSize);
    },
    onDialogClose() {
      this.editDialog.show = false;
    },
    async onEntrySubmit() {
      try {
        this.loadingOn();
        this.editDialog.show = false;
        this.$dialog.notify.info(`ロフトカートン予測情報を登録しました`, {
          timeout: 2300,
        });
        this.onSearchClick();
      } catch (error) {
        console.error("loftCartonSearch::onEntrySubmit", error);
        this.loadingOff();
        this.apiRequestError(error);
      }
    },
    async onEditSubmit(loftCarton) {
      try {
        this.selectedRow.cartonNumber = loftCarton.cartonNumber;
        this.selectedRow.shipmentPlanDate = loftCarton.shipmentPlanDate;
        this.selectedRow.companyStoreCode = loftCarton.companyStoreCode;
        this.selectedRow.storeName = loftCarton.storeName;
        this.selectedRow.cartonQuantity = loftCarton.cartonQuantity;
        this.selectedRow.remarks = loftCarton.remarks;
        this.selectedRow.updateDatetime = loftCarton.updateDatetime;
        this.selectedRow.updateUser = loftCarton.updateUser;
        this.gridApi.applyTransaction({ update: this.selectionRows });
        this.calcTotalItem();
        this.editDialog.show = false;
        this.$dialog.notify.info(`ロフトカートン予測情報を更新しました (${loftCarton.cartonNumber})`, {
          timeout: 2300,
        });
      } catch (error) {
        console.error("loftCartonSearch::onEditSubmit", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    createDateFromRules(value) {
      if (value == null || this.searchModel.createDateTo == null) {
        return true;
      }
      if (moment(value).isAfter(this.searchModel.createDateTo)) {
        return "出荷予定日Toより前の日付を指定してください";
      }
      return true;
    },
    createDateToRules(value) {
      if (value == null || this.searchModel.createDateFrom == null) {
        return true;
      }
      if (moment(value).isBefore(this.searchModel.createDateFrom)) {
        return "出荷予定日Fromより後の日付を指定してください";
      }
      return true;
    },
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.selectedPageSize = this.globalPageSize;
    this.defaultCsvExportParams = {
      allColumns: true,
      onlySelectedAllPages: false,
    };
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = [
      {
        headerName: "",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        filter: false,
        resizable: false,
        sortable: false,
        pinned: "left",
      },
    ];
    if (this.allowedAction("C090201")) {
      this.columnDefs = this.columnDefs.concat(this.cafereoColumnDefs);
    }

    this.rowData = [];
  },
  watch: {
    globalPageSize(size) {
      this.gridApi.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value + "px";
    },
  },
};
</script>
