import { createServiceSetting } from "../utils/ConstUtils";

// ここだけは既存影響が大きいのでサービス設定をラップして使用
// 注意）サービス設定指定は先頭小文字にしないと取得できない
export const corpList = createServiceSetting({
  LOFT: "specialCorporationCdForLoft", // ロフト法人CD
  SOFMAP: "specialCorporationCdForSofmap", // ソフマップ法人CD
  ANIMATE: "specialCorporationCdForAnimate", // アニメイト法人CD
  YODOBASHI: "specialCorporationCdForYodobashiCamera", // ヨドバシカメラ法人CD
  VILLAGEVANGUARD: "specialCorporationCdForVillageVanguard", // ヴィレッジヴァンガード法人CD
  TOKYUHANDS: "specialCorporationCdForTokyuHands", // 東急ハンズ法人CD
  AMAZON: "specialCorporationCdForAmazon", // AMAZON法人CD
});
